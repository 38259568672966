import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import TextField from '@beewise/text-field';
import Button from '@beewise/button';
import analytics from 'utils/analytics';
import { noop } from 'lodash-es';
import { fetchForgotPassword, fetchSignIn, setForgotPasswordError } from './actions/sign.in.actions';
import logo from '../PasswordSetup/beewise-logo.svg';

const defaultValues = {
    email: '',
    password: '',
};

const SignIn = () => {
    const dispatch = useDispatch();
    const { forgotPasswordError, forgotPasswordSent, error } = useSelector(state => state.signin);

    const {
        control,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues,
    });

    const email = watch('email');

    const validateEmail = () => {
        const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(email);
    };

    const submitSignIn = data => {
        const { email, password } = data;
        if (!email || !password) {
            return;
        }
        dispatch(fetchSignIn(email, password));

        analytics.fireGeneralEvent({
            action: 'Sign In',
        });
    };

    const toggleForgotPassword = () => {
        if (!email || !validateEmail()) {
            dispatch(setForgotPasswordError('Please enter valid email.'));
        } else {
            dispatch(setForgotPasswordError(null));
            dispatch(fetchForgotPassword(email));
        }
    };

    return (
        <div className="signin">
            <div className="signin-container">
                <img className="logo" src={logo} alt="Beewise logo" />
                <h2 className="signin-header">
                    View your ranch details, get visibility and insights into your pollination progress
                </h2>
                <div className="signin-form">
                    <h3 className="signin-form-header">{forgotPasswordSent ? 'Reset Password' : 'Log In'}</h3>
                    {forgotPasswordSent ? (
                        <div className="forgot-password">
                            <div className="forgot-password-margin">We have sent a Reset Password email to </div>
                            <div className="forgot-password">{email}</div>
                            <div className="forgot-password-margin">
                                Follow the directions in the email to reset your password.
                            </div>
                            <div className="forgot-password-tip forgot-password-margin">
                                <div>Haven&#39;t received the email yet?</div>
                                <div>
                                    Please check your spam and promotions folders, or{' '}
                                    <button className="forgot-password-resend" onClick={toggleForgotPassword}>
                                        resend
                                    </button>{' '}
                                    the email.
                                </div>
                            </div>
                        </div>
                    ) : (
                        <form className="change-password-form-block" onSubmit={handleSubmit(submitSignIn)}>
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: 'Email is required',
                                    validate: validateEmail,
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        placeholder="Email"
                                        error={errors.email ? 'Please enter valid email.' : forgotPasswordError}
                                        size="normal"
                                    />
                                )}
                            />
                            <Controller
                                name="password"
                                control={control}
                                rules={{ required: 'Password is required' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        type="password"
                                        placeholder="Password"
                                        size="normal"
                                        error={error}
                                        onKeyUp={e => {
                                            if (e.keyCode === 13) {
                                                submitSignIn();
                                            }
                                        }}
                                    />
                                )}
                            />
                            <div className="forgot-password-toggler" role="presentation" onClick={toggleForgotPassword}>
                                Forgot Password?
                            </div>
                            <Button
                                className="btn-primary primary-blue signin-form-button"
                                type="submit"
                                onClick={noop}
                            >
                                Log In
                            </Button>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SignIn;
