import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { noop } from 'lodash-es';
import TextField from '@beewise/text-field';
import Button from '@beewise/button';
import { getUrlParam } from '@beewise/utils';
import analytics from 'utils/analytics';
import { setPasswordAndUsername as setPasswordAndUsernameAction } from './actions';
import logo from './beewise-logo.svg';

import './styles.scss';

const defaultValues = {
    username: '',
    password: '',
    confirmPassword: '',
};

const PasswordSetup = () => {
    const isForgotPassword = getUrlParam('isForgotPassword');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        control,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({ defaultValues });
    const [submissionError, setSubmissionError] = useState('');

    const password = watch('password');

    const isPasswordValid = pwd => {
        const minLength = pwd.length >= 8;
        const hasNumber = /\d/.test(pwd);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(pwd);
        return minLength && hasNumber && hasSpecialChar;
    };

    const submitChangePassword = data => {
        analytics.fireGeneralEvent({
            action: 'Setting Up Username and Password',
        });
        dispatch(
            setPasswordAndUsernameAction(
                data.password,
                data.username,
                getUrlParam('confirmationToken'),
                () => {
                    navigate('/signin');
                },
                error => {
                    setSubmissionError(error);
                }
            )
        );
    };

    return (
        <div className="change-password">
            <div className="change-password-container">
                <img className="logo" src={logo} alt="Beewise logo" />
                <h2 className="change-password-header">
                    View your ranch details, get visibility and insights into your pollination progress
                </h2>
                <div className="change-password-form">
                    <h3 className="change-password-form-header">Setup your password</h3>
                    <p className="change-password-form-info">
                        Create a strong password with a mix of letters, numbers, and symbols.
                    </p>
                    <form className="change-password-form-block" onSubmit={handleSubmit(submitChangePassword)}>
                        <Controller
                            name="username"
                            control={control}
                            rules={{ required: !isForgotPassword }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    placeholder="Full Name"
                                    size="normal"
                                    error={errors.username ? 'Username is required' : ''}
                                />
                            )}
                        />
                        <Controller
                            name="password"
                            control={control}
                            rules={{
                                required: true,
                                validate: isPasswordValid,
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    type="password"
                                    placeholder="Password"
                                    size="normal"
                                    error={errors.password ? 'The password is not strong enough' : submissionError}
                                />
                            )}
                        />
                        <Controller
                            name="confirmPassword"
                            control={control}
                            rules={{
                                required: true,
                                validate: value => value === password || 'Passwords do not match.',
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    type="password"
                                    placeholder="Re-enter Password"
                                    size="normal"
                                    error={errors.confirmPassword ? errors.confirmPassword.message : ''}
                                />
                            )}
                        />
                        <Button
                            className="btn-primary primary-blue change-password-button"
                            type="submit"
                            onClick={noop}
                        >
                            Done
                        </Button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default PasswordSetup;
